import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper,WrapperFlexResp,WrapperFlexRespBurn, InfoRow, Column1, Column2,Column3, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img,Icon } from './RoadmapElements'
import * as s from "../Minter/styles/globalStyles.js";
import SeedSample from '../../images/seed-cross.png'
import SeedBurningSample from '../../images/seed-burning.png'
import BabySample from '../../images/baby-joint.png'
import AdultMysterious from '../../images/adult-hidden.png'

import IconTitle from '../../images/icon-cannabis.png'
import IconWarning from '../../images/icon-warning.png'

import Icon1 from '../../images/room-garden.png'
import Icon2 from '../../images/room-laboratory.png'
import Icon3 from '../../images/room-zen.png'
import Icon4 from '../../images/room-kitchen.png'
import Icon5 from '../../images/room-bedroom.png'
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServiceElements'



const Roadmap = ({lightBg, id,ResponsiveWrapper, imgStart,TextWrapperTitle, topLine, topLine2, lightText, headline, darkText, description2, description, buttonLabel, alt, img,icon, primary, dark, dark2 }) => {
  return (
    <>





<s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#FFFAF2" , "min-height": "700px" ,}}
        id={"roadmap"}
      >
        <WrapperFlexResp flex={1} style={{ "padding-left": "2em" }} test>
         <s.Container flex={1} jc={"right"} ai={"left"}>
            <Icon src={IconTitle} style={{ "width":"80px"}}/>
            <s.TextTitle >Our Growmap</s.TextTitle>
         </s.Container>
       </WrapperFlexResp>    

        <WrapperFlexResp flex={1} style={{ padding: 12 , "height":"auto"}} test>
          <s.Container flex={1} jc={"right"} ai={"left"} style={{ "max-width":"150px", "top":"0","border-right": "4px solid black"}}>
              <s.TextTitle style={{ "padding-left":"1.5em"}}>1</s.TextTitle>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"left"} ai={"left"}>
              <s.TextSubTitle lightText={lightText}>Get your free Seeds</s.TextSubTitle>
              <s.TextDescription darkText={darkText}>The WAGMI Grow House <b>offers 5000 seeds</b> (limited to 3 per subscriber)</s.TextDescription>
              <s.TextDescription>At the onset of your subscription, you will receive up to 3 free seeds (for a limited period of time).</s.TextDescription>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"center"} ai={"center"}>
           
              <Img src={SeedSample} alt={alt} style={{ "width":"75%","max-width":"380px"}}/>
              
          </s.Container>
       </WrapperFlexResp>

       <s.SpacerXLarge />

       <WrapperFlexResp flex={1} style={{ padding: 12 }} test>
          <s.Container flex={1} jc={"right"} ai={"left"} style={{ "max-width":"150px", "top":"0","border-right": "4px solid black"}}>
              <s.TextTitle style={{ "padding-left":"1.5em"}}>2</s.TextTitle>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"left"} ai={"left"}>
              <s.TextSubTitle lightText={lightText}>Choose where to Grow</s.TextSubTitle>
              <s.TextDescription darkText={darkText}>You will personally choose <b>where you want to plant your seed</b> (indoors, outdoors…) which will ultimately <b>influence your Baby Bud’s appearance</b>. </s.TextDescription>
          </s.Container>
       </WrapperFlexResp>
    </s.Container>
    
    <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#FFF5E4" , "min-height": "400px" ,}}
      >
      <ServicesWrapper>
        <ServicesCard text-align="end">
          <ServicesIcon src={Icon1}/>
          <s.SpacerMedium/>
          <s.TextServiceSubTitle>Garden</s.TextServiceSubTitle>
          <s.SpacerMedium/>
          <s.TextServicesDesc text-align="end">Seeds are grown outdoors under the sun in a natural environment, for a healthy and organic result</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon2}/>
        <s.SpacerMedium/>
          <s.TextServiceSubTitle>Toxic Lab</s.TextServiceSubTitle>
          <s.SpacerMedium/>
          <s.TextServicesDesc>Seeds are cultivated as experimental subjects, with evil and unexpected attributes</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <s.SpacerMedium/>
          <s.TextServiceSubTitle>Zen Room</s.TextServiceSubTitle>
          <s.SpacerMedium/>
          <s.TextServicesDesc>Cultivation in a peaceful zone with meditation, for a zen and angelic result</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
        <s.SpacerLarge/>
          <ServicesIcon src={Icon4}/>
          <s.SpacerLarge/>
          <s.TextServiceSubTitle>Kitchen</s.TextServiceSubTitle>
          <s.SpacerMedium/>
          <s.TextServicesDesc>Seeds are grown by eating everything they find in the kitchen, for a result high in flavors</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
        <s.SpacerLarge/>
          <ServicesIcon src={Icon5}/>
          <s.SpacerLarge/>
          <s.TextServiceSubTitle>Play Room</s.TextServiceSubTitle>
          <s.SpacerMedium/>
          <s.TextServicesDesc>Tumultuous seeds are grown in the playroom, for a playful Baby Bud</s.TextServicesDesc>
          <s.SpacerMedium/>
        </ServicesCard>
      </ServicesWrapper>
    </s.Container>

   
    <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#FFFAF2" , "min-height": "630px" ,}}
      >   
 <s.SpacerXLarge/>
        <WrapperFlexResp flex={1} style={{ padding: 12 , "height":"auto"}} test>
          <s.Container flex={1} jc={"right"} ai={"left"} style={{ "max-width":"150px", "top":"0","border-right": "4px solid black"}}>
              <s.TextTitle style={{ "padding-left":"1.5em"}}>3</s.TextTitle>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"left"} ai={"left"} style={{ "height":"auto"}}>
              <s.TextSubTitle lightText={lightText}>Grow your Seed into a Baby Bud</s.TextSubTitle>
              <s.TextDescription darkText={darkText}><b>Baby Buds</b> have more than 200 traits and there is a limited supply of <b>5000 NFTs</b></s.TextDescription>
              <s.TextDescription darkText={darkText}>Every Baby Bud is unique and offers it's owner the opportunity to use it as a profile picture, or to harvest it and become a very special Bud</s.TextDescription>
              <WrapperFlexRespBurn flex={1} style={{ padding: 12 , "height":"100px"}} test>
                 <s.Container flex={1} jc={"left"} ai={"left"} style={{ "height":"120px",backgroundColor: "#FFF5E4","max-width":"100px"}}>
                 <Img src={SeedBurningSample} alt={alt} style={{ "width":"90%","max-width":"150px"}}/>
                 </s.Container>
                 <s.Container flex={1} jc={"left"} ai={"center"} style={{ "height":"120px",backgroundColor: "#FFF5E4","vertical-align": "middle","max-width":"100px"}}>
                 <Icon src={IconWarning} style={{ "width":"40px","left":"0%","top":"40%","position":"relative"}}/>
                 <s.SpacerLarge />
                 </s.Container>
                 <s.Container flex={1} jc={"left"} ai={"center"} style={{ "height":"120px",backgroundColor: "#FFF5E4","vertical-align": "middle"}}>
                 <s.TextDescription style={{ "float":"right","top":"20%","position":"relative"}} darkText={darkText}>For each Baby Bud grown, a Seed is burnt</s.TextDescription>
                </s.Container>
            </WrapperFlexRespBurn>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"center"} ai={"center"}>
           
              <Img src={BabySample} alt={alt} style={{ "width":"100%","max-width":"400px"}}/>
              
          </s.Container>
       </WrapperFlexResp>
       </s.Container>
      

       <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#FFFAF2" , "min-height": "380px" ,}}
      >  
       <WrapperFlexResp flex={1} style={{ padding: 12 }} test>
          <s.Container flex={1} jc={"right"} ai={"left"} style={{ "max-width":"150px", "top":"0","border-right": "4px solid black"}}>
              <s.TextTitle style={{ "padding-left":"1.5em"}}>4</s.TextTitle>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"left"} ai={"left"}>
              <s.TextSubTitle lightText={lightText}>Next evolution is coming soon...</s.TextSubTitle>
              <s.TextDescription>Keep growing to the next evolution.</s.TextDescription>
          </s.Container>
          <s.SpacerSmall />
          <s.Container flex={1} jc={"center"} ai={"center"}>           
              <Img src={AdultMysterious} alt={alt} style={{ "width":"100%","max-width":"430px"}}/>         
          </s.Container>
       </WrapperFlexResp>
    </s.Container>
   
    </>
  )
}

export default Roadmap
