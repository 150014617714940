import React, {useState} from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Info from '../components/Info';
import { homeObjOne, homeObjTwo, homeObjThree} from '../components/Info/Data';
import Navbar from '../components/Navbar';
import Sidebar from '../components/SideBar';
import Roadmap from '../components/Roadmap';
import Staff from '../components/Staff';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Roadmap {...homeObjOne}/>
      <Info {...homeObjOne}/>
      <Staff/>
      <Footer />
    </>
  )
}

export default Home
