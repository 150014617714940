export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  headline: 'Who we are',
  topLine: 'The concept',
  description: 'There are currently clubs, smoke shops and farms, but no place offers its members the possibility to control all the steps in their weed plantation. We offer each of our members the possibility to grow his or her own choice of weed from seed germination to cultivation.',
  topLine2: 'The WAGMI GROW TEAM',
  description2: 'The Story of the WAGMI Grow Team goes back to the summer of 2019, during a blazed talk between four friends who have a common passion for cultivating high quality weed. These friends have a unique and complementary profile, combining their talents to create the best cross bred weed in the world.',
  imgStart: true,
  icon: '/images/icon-coeur.png',
  img: '/images/seed-sample-prez.png',
  alt: 'seed',
  dark: true,
  primary: true,
  darkText: true
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description: 'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: '/images/svg-4.svg',
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Premium Bank',
  headline: 'Creating an account is extremely easy',
  description: 'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Start Now',
  imgStart: false,
  img: '/images/svg-3.svg',
  alt: 'car',
  primary: false,
  darkText: true
};
