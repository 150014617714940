import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { FaTimes } from 'react-icons/fa'

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`
export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`
export const SidebarWrapper = styled.div`
  color: #fff;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;
  align-items: center;

  justify-content: center;

  @media  screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`
export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 02s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`
export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
`
export const SidebarRoute = styled(LinkR)`
border: 4px solid #FFFAF2;
  box-sizing: border-box;
  border-radius: 6px;

  white-space: nowrap;
  padding: 10px 22px;
  color: #FFFAF2;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;



  &:hover {
    transition: all 0.2s ease-in-out;
    background: #FFFAF2;
    color: #000000;
  }
`
export const NavSocialItem = styled.img`


  display: inline-block;
  background-size: 140%;

  box-sizing: border-box;
  white-space: nowrap;
  padding: 10px 10px;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  width: 45px;
  height: 45px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;


  &:hover {
    transition: all 0.2s ease-in-out;
    background: #666666;
    color: #000000;
    fill:  #000000;
  }
  ` 