import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#FFFAF2' : '#010606')};
  height: 2000px;
  

  @media screen and  (max-width: 768px) {
    padding: 100px 0;
  }
`
export const InfoWrapper = styled.div`
display: grid;

  height: 300px;
  width: 100%auto;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;

`
export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(1fr, auto);
  height: 300px;

  align-items: left;
  justify-content: left;
  text-align: left;
  
  grid-template-areas:  'col1 col2 col3';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1 col2 col3';
  }
`



export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  width: 100%;
  max-width: 100px;
  margin-left: 0; 
  margin-right: auto;
  align-items: right;
  justify-content: right;
  text-align: right;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  width: 100%;
  max-width: 400px;
  grid-area: col2;
  align-items: left;
  justify-content: left;
  text-align: left;
  
`
export const Column3 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  width: 100%;
  max-width: 400px;
  grid-area: col3;
  align-items: left;
  justify-content: left;
  text-align: left;
  
`

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  align-items: left;
  justify-content: left;
  text-align: left;
  
`

export const TextWrapperTitle = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  align-items: left;
  justify-content: left;
  text-align: left;

`
export const TopLine = styled.p`
  color: #010606;
  font-family: 'Titan One';
  font-style: normal;
  font-size: 46px;
  line-height: 51px;
  font-weight: normal;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600px;
  color: ${({lightText}) => (lightText ? '#FFFAF2' : '#010606')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const Subtitle  = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`
export const BtnWrap = styled.div`
  display:flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  justify-content: center;
`;
export const Img = styled.img`
  width: 35%;
  height: auto;
  margin: 0 0 10px 0;
  padding-right: 0;

  
`;
export const Icon = styled.img`

  width: 50px;
  height: auto;
  padding-right: 0;
  right: 50px;
  top: 35px;
  position: relative;
`;


export const WrapperFlexResp = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  max-width: 1100px;
  @media (min-width: 767px) {
    flex-direction: row;
    max-height: 200px;
  }
`;

export const WrapperFlexRespBurn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  max-width: 1100px;
  }
`;