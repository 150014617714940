import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavBtnLink, NavSocialMenu, NavSocialItem } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import discordIcon from '../../images/discord-icon.svg'
import twitterIcon from '../../images/twitter-icon.svg'
import openseaIcon from '../../images/opensea-icon.svg'


const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>WAGMIGROW</NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to='roadmap'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Roadmap</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='about'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >About us</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='staff'
              smooth={true} duration={500} spy={true} exact='true' offset={-80}
              >Staff</NavLinks>
            </NavItem>
          </NavMenu>
          <NavSocialMenu>
          <a href="https://opensea.io/collection/wagmi-seeds"><NavSocialItem src={openseaIcon}/></a>
          <a href="https://discord.gg/PJNu2yuxQA"><NavSocialItem src={discordIcon}/></a>
          <a href="https://www.twitter.com/wagmigrowhouse"><NavSocialItem src={twitterIcon}/></a>
          </NavSocialMenu>
          <NavBtn>
            <NavBtnLink to='/minting'>Let's Grow!</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
