const { MerkleTree } = require('merkletreejs');
const { isAddress, toChecksumAddress } = require('web3-utils');
const keccak256 = require('keccak256');
const {wlAddresses} = require('./wlAddresses.js');


const processAddress = (address) => {
    address = toChecksumAddress(address)

    if (isAddress(address)) {
        return address
    }
    // TODO: parse ens domains

    return null
}

const getAirdropTree = (addresses) => {

    const leaves = addresses
        .map(x => processAddress(x))
        .filter(x => !!x)
        .map(x => keccak256(x))

    const tree = new MerkleTree(leaves, keccak256, { sort: true })

    const root = tree.getHexRoot()

    return {
        tree,
        root,
    }

}


const isWhiteListed = (claimingAddress) => {
    const { tree } = getAirdropTree(wlAddresses)
    const claimingAddresseLeaf = keccak256(processAddress(claimingAddress)).toString('hex')
    const rootHash = tree.getRoot()
    const hexProof = tree.getHexProof(claimingAddresseLeaf)

    const whiteListed = tree.verify(hexProof, claimingAddresseLeaf,rootHash);

    return {
        whiteListed,
        hexProof,
    }

}


module.exports = { getAirdropTree, processAddress, isWhiteListed }