import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  background: #FFFAF2;
  @media screen and (max-width: 1050px) {
    height: 1100px;
  }

 `

 export const TextWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 40px;
  padding-left: 50px;
  text-align: left;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row-reverse;
`
export const ServicesWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr 1fr;
      padding: 0 20px;
  }
`
export const ServicesCard = styled.div`
  background: #FFFAF2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 450px;
  width: 100%;
  
  padding:  10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
`
export const ServicesIcon = styled.img`
  height: auto;
  width: 100%;
  max-width: 230px;
  margin-bottom: 10px;

`
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ServicesH2 = styled.h2`
  font-size: 2rem;

`

export const ServicesP = styled.p`
  font-size: 1rem;
`
export const ServicesPs = styled.p`
  font-size: 0.8rem;
  margin-bottom: 10px;
`


export const Icon = styled.img`

  width: 50px;
  height: 50px;
  padding-right: 0;
  right: 50px;
  top: 35px;
  position: relative;
`