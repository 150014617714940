import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img,Icon } from './InfoElements'
import * as s from "../Minter/styles/globalStyles.js";

const Info = ({lightBg, id, imgStart, topLine, topLine2, lightText, headline, darkText, description2, description, buttonLabel, alt, img,icon, primary, dark, dark2 }) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <Icon src={icon}/>
                <s.TextTitle style={{ "padding-left":"30px"}}>{headline}</s.TextTitle>
              </TextWrapper>
            </Column1>
          </InfoRow>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <s.TextSubTitle lightText={lightText}>{topLine}</s.TextSubTitle>
                <s.TextDescription darkText={darkText}>{description}</s.TextDescription>
                <s.SpacerLarge/>
                <s.TextSubTitle lightText={lightText}>{topLine2}</s.TextSubTitle>
                <s.TextDescription darkText={darkText}>{description2}</s.TextDescription>
              </TextWrapper>
            </Column1>
            <s.SpacerLarge />
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Info
