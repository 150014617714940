import React, {useState} from 'react'
import { StyledLogo,ResponsiveWrapper,CountdownWrap,DateItemDiv, StyledImg,StyledImg2, BtnLink,Icon,Icon2} from './HeroElements'
import WagmiLogo from '../../images/WAGMI-logo.png'
import BbudSample from '../../images/welcome-samples.gif'
import GifSample from '../../images/gif-samples.gif'
import IconStar from '../../images/star.svg'
import * as s from "../Minter/styles/globalStyles.js";
import Countdown from 'react-countdown';
import { useHistory } from "react-router-dom";


const Hero = () => {
  const [hover, setHover] = useState(false);
  const [onSale, setOnSale] = useState(true);
  const [mintButtonText, setMintButtonText] = useState("Mint Now");
  const [onSaleText, setOnSaleText] = useState("");
  const [showIcon, setShowIcon] = useState("true");

  // Random component
  const Completionist = () => <span></span>;
  const history = useHistory();

  const onHover = () => {
    setHover(!hover)
  }


  
  const goToMint = () =>{ 
    let path = `/minting`; 
    history.push(path);
  }





// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    setOnSale(true); 
    setMintButtonText("Let's Grow!"); 
    setShowIcon("true");
    setOnSaleText("");
    return <Completionist />;
  } else {
    // Render a countdown
    setOnSale(false); 
    setMintButtonText("");
    setOnSaleText("Grow Season 1 is SOLD OUT! Season 2 will start soon!");
    setShowIcon("none");
    return  ( 
    <CountdownWrap>
      <DateItemDiv>
      <s.TextCounter>{days}</s.TextCounter>
      <s.TextDescription>Days</s.TextDescription>
      </DateItemDiv>
      <DateItemDiv>
        <s.TextCounter>{hours}</s.TextCounter>
        <s.TextDescription>Hrs</s.TextDescription>
      </DateItemDiv> 
      <DateItemDiv>
        <s.TextCounter>{minutes}</s.TextCounter>
        <s.TextDescription>Mins</s.TextDescription>
      </DateItemDiv>
      <DateItemDiv style={{"border-right": "none"}}>
        <s.TextCounter>{seconds}</s.TextCounter>
        <s.TextDescription>Secs</s.TextDescription>
      </DateItemDiv>  
  </CountdownWrap>
  )
  }
};



  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#FFBD35" , "min-height": "800px" ,}}
      >
        <s.SpacerXLarge />
        <StyledLogo alt={"logo"} src={WagmiLogo} />
        <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <Icon style={{display: onSale ? 'block' : 'none' }} src={IconStar}/>
          <Icon2 style={{display: onSale ? 'block' : 'none' }} src={IconStar}/>
          <BtnLink  disabled={onSale ? false : true} onClick={goToMint} >{mintButtonText}</BtnLink>
          <s.TextPunchline>{onSaleText}</s.TextPunchline>
          <s.SpacerLarge />
          <Countdown date={'2022-06-12T22:00:00+02:00'} renderer={renderer}/>
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={BbudSample} />
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <s.TextPunchline>The first evolving Weed NFT project for the metaverse!</s.TextPunchline>
          </s.Container>
        </ResponsiveWrapper>

      </s.Container>
    </s.Screen>
  )
}

export default Hero
