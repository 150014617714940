import React, { useEffect, useState, useRef } from "react";
import Switch from '@mui/material/Switch';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Numbers from '@mui/icons-material/Numbers';

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { isWhiteListed } from "./redux/whitelist/utils-whitelist.js";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import WagmiLogo from '../../images/WAGMI-logo.png'
import seedIcon from '../../images/seed-burning.png'
import Illus0 from '../../images/room-seed-illustration.png'
import Illus1 from '../../images/room-garden-illustration.png'
import Illus2 from '../../images/room-toxic-illustration.png'
import Illus3 from '../../images/room-zen-illustration.png'
import Illus4 from '../../images/room-kitchen-illustration.png'
import Illus5 from '../../images/room-play-illustration.png'
import FreeMintImg from '../../images/freemint-alert.png'
import Icon0 from '../../images/room-seeds.png'
import Icon1 from '../../images/room-garden.png'
import Icon2 from '../../images/room-laboratory.png'
import Icon3 from '../../images/room-zen.png'
import Icon4 from '../../images/room-kitchen.png'
import Icon5 from '../../images/room-bedroom.png'
import { ServicesCard, ServicesIcon} from '../Roadmap/ServiceElements'



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

    



export const StyledButtonInit = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton = styled.button`

  border: 4px solid #000000;
  background: #FFFAF2;
  box-sizing: border-box;
  border-radius: 6px;

  white-space: nowrap;
  padding: 14px 30px;
  font-size: 30px;
  color: #000000;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  
  &:disabled {
    background: #FFBD35  ;
    border: none;
    color: #000000 ;
  }

  &:hover:enabled {
    transition: all 0.2s ease-in-out;
    background: #000000;
    color: #FFFAF2;


  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }App
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 100%;
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
  
  background-color: var(--accent);
  width: 80px;
  @media (min-width: 900px) {
    width: 100px;
  }
  @media (min-width: 1000px) {
    width: 100px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #5dade2 ;
  text-decoration: none;
`;

export const RoomCard = styled.button`
  background: #FFFAF2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 125px;
  width: 125px;
  
  box-sizing: border-box;
  border-radius: 6px;
  
  padding:  8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
    border: 6px solid #778899;
  }
  &:active {
    border-bottom: 3px solid #01bf71
  }
`
export const RoomIcon = styled.img`
  height: auto;
  width: 60%;
  margin-bottom: 10px;

`
export const smallFont = styled.p`
  font-size: 0.7rem;
  margin-bottom: 10px;
`

export const NavButton = styled.button`
  text-decoration: none;
  background-color: #000;
  display: inline-block;
  padding: 8px 16px;
  color: white;
  border-radius: 50%;

&:hover {
  background-color: #ddd;
  color: black;
}
`

const Minter = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [babyMode, setBabyMode] = useState(true);
  const [growMode, setGrowMode] = useState(false);
  const [room, setRoom] = useState("Garden");
  const [roomColor, setRoomColor] = useState("#BCFFC7");
  const [roomId, setRoomId] = useState(0);
  const label = { inputProps: { 'aria-label': 'Switch label' } };
  const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(3);
  const [mintAmountBaby, setMintAmountBaby] = useState(2);
  const [numberOfBabies, setNumberOfBabies] = useState(0);


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_MINT_AMOUNT_PER_SESSION: 3,
    CONTRACT_ADDRESS: "",
    BABY_SCAN_LINK: "",
    BABY_NFT_NAME: "",
    BABY_SYMBOL: "",
    BABY_MAX_SUPPLY: 1,
    BABY_WEI_COST: 0,
    BABY_DISPLAY_COST: 0,
    BABY_GAS_LIMIT: 0,
    BABY_MARKETPLACE: "",
    BABY_MARKETPLACE_LINK: "",
    BABY_MAX_MINT_AMOUNT_PER_SESSION: 3,
  });


  const prepareSeedsForGrowing = () => {
    let gasLimitBase = CONFIG.GAS_LIMIT_BASELINE;
    let totalGasLimit = String(gasLimitBase);
    let isPreparedAlready = getIsPreparedAlready();

    if (!isPreparedAlready[0]){
      setFeedback(`Preparing your seed to be grown`);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .setApprovalForAll(CONFIG.BABY_CONTRACT_ADDRESS,true)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `All your seeds are now Growable.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });

    }
    else{setFeedback(`The seeds are already prepared`);}

  };

 
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let gasLimitBase = CONFIG.GAS_LIMIT_BASELINE;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount + gasLimitBase);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const directMintBaby = () => {
    let cost = CONFIG.BABY_WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let gasLimitBase = CONFIG.GAS_LIMIT_BASELINE;
    let totalCostWei = String(3 * cost * mintAmountBaby);
    let totalGasLimit = String(gasLimit * mintAmountBaby + gasLimitBase);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.BABY_NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContractBaby.methods
      .mint(mintAmountBaby,roomId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.BABY_CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.BABY_NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getIsPreparedAlready = async() => {
    const c = blockchain.smartContract.methods.isApprovedForAll(blockchain.account,CONFIG.BABY_CONTRACT_ADDRESS).call()
    const result = await Promise.all([c]);

    return result;
   }

  const getTokenData = async(tokenId) => {
    console.log("token id paassé a la fonction de datafetch: ",tokenId);
    const a = blockchain.smartContract.methods.totalSupply().call()
    const b = blockchain.smartContract.methods.ownerOf(tokenId).call()
    const c = blockchain.smartContract.methods.isApprovedForAll(blockchain.account,CONFIG.BABY_CONTRACT_ADDRESS).call()
    const result = await Promise.all([a, b, c]);

    return result;
   }

  const verifySeedTokenId = (tokenId) => {
    console.log("Verify SEED with token id: ",tokenId);
    if(Number.isInteger(tokenId) && tokenId >= 0 && tokenId < 5000){
      console.log("The token ID format is OK");
      setFeedback(`The token ID format is OK`);

      let tokenData = getTokenData(tokenId);

      tokenData.then(value => {
        console.log("tot supply =",value[0]); 
        console.log("token owner =",value[1]); 
        console.log("is approved 4 all? =",value[2]); 
        console.log("token id =",tokenId); 
        console.log("your account =",blockchain.account); 
        console.log("Baby Adress =",CONFIG.BABY_CONTRACT_ADDRESS); 
        if (tokenId < parseInt(value[0]) && value[1].toLowerCase() === blockchain.account.toLowerCase() ){
          //if (value[2].toLowerCase()=== CONFIG.BABY_CONTRACT_ADDRESS.toLowerCase() ){
          if (value[2]){
            setFeedback(`The seed can be grown`);
            growMintBaby(tokenId);

          }
          else{setFeedback(`The seed you are trying to grow is not Prepared use the "prepare seeds" button`);}
        }
        else{setFeedback(`You are not the owner of this Seed token`);}
      }).catch(err => {
        console.log(err); 
        setFeedback(`Something went wrong: Check if you are the owner of the Seed token`);
        
      });
    }
    else{setFeedback(`The token ID submited is wrong format! It must be a number included in [0-4999]`);}

  }

  const growMintBaby = (tokenId) => {
    let cost = CONFIG.BABY_WEI_COST;
    let gasLimit = CONFIG.BABY_GAS_LIMIT;
    let gasLimitBase = CONFIG.BABY_GAS_LIMIT_BASELINE;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit + gasLimitBase);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.BABY_NFT_NAME}...`);
    setClaimingNft(true);
    console.log("tokenId",tokenId)
    console.log("roomId",roomId)
    blockchain.smartContractBaby.methods
      .growMint(tokenId,roomId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.BABY_CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.BABY_NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAX_MINT_AMOUNT_PER_SESSION) {
      newMintAmount = CONFIG.MAX_MINT_AMOUNT_PER_SESSION;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountBaby = () => {
    let newMintAmount = mintAmountBaby - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmountBaby(newMintAmount);
  };

  const incrementMintAmountBaby = () => {
    let newMintAmount = mintAmountBaby + 1;
    if (newMintAmount > CONFIG.BABY_MAX_MINT_AMOUNT_PER_SESSION) {
      newMintAmount = CONFIG.BABY_MAX_MINT_AMOUNT_PER_SESSION;
    }
    setMintAmountBaby(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const switchChange = (event) => {
    setGrowMode(event.target.checked);
  };

  const changeRoom=(newRoom)=> {

    setFeedback(`Get your NFTs down there`);

    switch (newRoom) {
      case "Seeds Market": 
        setRoom("Seeds Market");
        setRoomColor("#FFDE9E");
        setRoomId(99);
        document.getElementById("illustrationRoom").src=Illus0
        setBabyMode(false)
      break;
      case "Garden": 
        setRoom("Garden");
        setRoomColor("#BCFFC7");
        setRoomId(0);
        document.getElementById("illustrationRoom").src=Illus1
        setBabyMode(true)
      break;
      case "Toxic Lab": 
        setRoom("Toxic Lab");
        setRoomColor("#FF4B4B");
        setRoomId(1);
        document.getElementById("illustrationRoom").src=Illus2
        setBabyMode(true)
      break;
      case "Zen Room": 
        setRoom("Zen Room");
        setRoomColor("#99DAFF");
        setRoomId(2);
        document.getElementById("illustrationRoom").src=Illus3
        setBabyMode(true)
      break;
      case "Kitchen": 
        setRoom("Kitchen");
        setRoomColor("#F6FFBC");
        setRoomId(3);
        document.getElementById("illustrationRoom").src=Illus4
        setBabyMode(true)
      break;
      case "Play Room": 
        setRoom("Play Room");
        setRoomColor("#625FFF");
        setRoomId(4);
        document.getElementById("illustrationRoom").src=Illus5
        setBabyMode(true)
      break;
      default:
        setRoom("Seeds Market");
        setBabyMode(false)
   }
  }


  class Gallery extends React.Component {
    items = [
      <RoomCard style={{"background": "#FFDE9E", border: room === "Seeds Market" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Seeds Market")}>
      <RoomIcon src={Icon0}/>
      <s.TextSubTitle style={{"font-size":"14px"}} >Seeds Market</s.TextSubTitle>
    </RoomCard>,
      <RoomCard style={{"background": "#BCFFC7", border: room === "Garden" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Garden")}>
      <RoomIcon src={Icon1}/>
      <s.TextSubTitle style={{"font-size":"14px"}}>Garden</s.TextSubTitle>
    </RoomCard>,
    <RoomCard style={{"background": "#FF4B4B", border: room === "Toxic Lab" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Toxic Lab")}>
    <RoomIcon src={Icon2}/>
      <s.TextSubTitle style={{"font-size":"14px"}}>Toxic Lab</s.TextSubTitle>
    </RoomCard>,
    <RoomCard style={{"background": "#99DAFF", border: room === "Zen Room" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Zen Room")}>
      <RoomIcon src={Icon3}/>
      <s.TextSubTitle style={{"font-size":"14px"}}>Zen Room</s.TextSubTitle>
    </RoomCard>,
    <RoomCard style={{"background": "#F6FFBC", border: room === "Kitchen" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Kitchen")}>
      <RoomIcon src={Icon4}/>
      <s.TextSubTitle style={{"font-size":"14px"}}>Kitchen</s.TextSubTitle>
    </RoomCard>,
    <RoomCard style={{"background": "#625FFF", border: room === "Play Room" ? '6px solid #000000' : '' }} onClick={()=>changeRoom("Play Room")}>
      <RoomIcon src={Icon5}/>
      <s.TextSubTitle style={{"font-size":"14px"}}>Play Room</s.TextSubTitle>
    </RoomCard>

  ];
  
    state = {
      galleryItems: this.items.map((i) => <h2 key={i}>{i}</h2>),
    }

    responsive = {
      0: { items: 2 },
      500: { items: 3 },
      950: { items: 6 },
    }
  
    thumbItem = (item, i) => (
      <span key={item} onClick={() => this.Carousel.slideTo(i)}>
        *{' '}
      </span>
    )


    render() {
      return (
        <ResponsiveWrapper flex={1} style={{ padding: 24 , "max-width":"900px","max-height":"200px", "align-items": "center",backgroundColor: roomColor}} test>

          <AliceCarousel
            disableDotsControls={false}
            disableButtonsControls={true}
            responsive={this.responsive}
            items={this.state.galleryItems}
            ref={(el) => (this.Carousel = el)}
          />

          </ResponsiveWrapper>
          
      )
    }
  }

  class SeedMinter extends React.Component {

    render() {
      return (
        <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        style={{
          backgroundColor: "var(--secondary)",
          padding: 24,
          borderRadius: 24,
          border: "4px dashed var(--secondary)",
          "max-height": "500px",
          "max-width": "500px"
        }}
      >
            <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  {room}
            </s.TextTitle>
            <s.TextDescription style={{textAlign: "center",color: "var(--accent-text)"}} >
            Welcome to the Seed Room ! Here is the place where you get the 3 seeds you’ve been waiting for. 
            </s.TextDescription>

            <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
              <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
               
               <smallFont>Supply:</smallFont>
                <s.TextPunchline
                  style={{
                    textAlign: "center",
                    fontSize: 40,
                    color: "var(--accent-text)",
                  }}
                >
                 {data.totalSupply}/{CONFIG.MAX_SUPPLY}
                </s.TextPunchline>
              </s.Container>
              <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
               
               <smallFont>Unit Price:</smallFont>
                <s.TextPunchline
                  style={{
                    textAlign: "center",
                    fontSize: 40,
                    color: "var(--accent-text)",
                  }}
                >
                 0.01 Eth
                </s.TextPunchline>
              </s.Container>
            </s.Container>

            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
        
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect Metamask
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />

                    <s.Container ai={"center"} jc={"center"} fd={"row"} >
                    <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Minting " : "Claim "}{mintAmount} Seeds
                      </StyledButton>
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
            </s.Container>   
      )}
    }


    class BabyMinter extends React.Component {

      render() {
        return (
          <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "var(--secondary)",
            padding: 24,
            borderRadius: 24,
            border: "4px dashed var(--secondary)",
            "max-height": "500px",
            "max-width": "500px"
          }}
        >
              <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    {room}
              </s.TextTitle>
              <s.TextDescription style={{textAlign: "center",color: "var(--accent-text)"}} >
              Welcome to the {room} ! Here is the place where you can grow your Seeds. 
              </s.TextDescription>
  
              <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
                <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
                 
                 <smallFont>Supply:</smallFont>
                  <s.TextPunchline
                    style={{
                      textAlign: "center",
                      fontSize: 40,
                      color: "var(--accent-text)",
                    }}
                  >
                   {data.totalSupplyBaby}/{CONFIG.BABY_MAX_SUPPLY}
                  </s.TextPunchline>
                </s.Container>
                <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
                 
                 <smallFont>Buy/Grow Mode</smallFont>
                 <Switch {...label} checked={growMode} onChange={switchChange} defaultChecked />
                </s.Container>
              </s.Container>
  
              <s.SpacerSmall />
              {Number(data.totalSupplyBaby) >= CONFIG.BABY_MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
          
                  <s.SpacerSmall />
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        Connect Metamask
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                     
                      <s.SpacerMedium />
                      { growMode ? <GrowMode /> : <BuyMode /> }

                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
              </s.Container>   
        )}
      }


      class GrowMode extends React.Component {

        constructor(){
          super();
          this.state = {value: ''};
          this.onChange = this.onChange.bind(this)
       }
       
       onChange(e){
          const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
             this.setState({value: e.target.value})
          }
       }

        render() {
          return (
            <s.Container ai={"center"} jc={"center"} fd={"column"} >
            <s.Container ai={"center"} jc={"center"} fd={"row"} >
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
               
               <smallFont>Growing Price:</smallFont>
                <s.TextPunchline
                  style={{
                    textAlign: "center",
                    fontSize: 40,
                    color: "var(--accent-text)",
                  }}
                >
                 0.02 Eth
                </s.TextPunchline>
              </s.Container>
              <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          style={{ 
                            fontSize: 14, 
                            border: "2px dashed #000000",
                            padding: "5px 5px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            prepareSeedsForGrowing();
                            getData();
                          }}
                        >
                          Prepare Seeds for growing
                        </StyledButton>
            </s.Container>
            <s.Container ai={"center"} jc={"center"} fd={"row"} >
                <RoomIcon src={seedIcon} style={{"height": "50px","width": "36px"}}/>
                <s.SpacerMedium />
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Numbers sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField id="seed-token-to-grow" label="Seed token to Grow" variant="standard" helperText="" placeholder="[0-4999]" value={this.state.value} onChange={this.onChange}/>
                      </Box>
                    
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            getData();
                            verifySeedTokenId(parseInt(this.state.value));

                            
                          }}
                        >
                          {claimingNft ? "Minting " : "Grow"}
                        </StyledButton>
                        
                      </s.Container>
                      </s.Container>
          )
      }}

      class BuyMode extends React.Component {

        render() {
          return (
            <s.Container ai={"center"} jc={"center"} fd={"column"} >
            <s.Container ai={"center"} jc={"center"} fd={"row"} >
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"column"}>
               
               <smallFont>Unit Price:</smallFont>
                <s.TextPunchline
                  style={{
                    textAlign: "center",
                    fontSize: 40,
                    color: "var(--accent-text)",
                  }}
                >
                 0.06 Eth
                </s.TextPunchline>
              </s.Container>
            </s.Container>
            <s.Container ai={"center"} jc={"center"} fd={"row"} >
                      <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmountBaby();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            directMintBaby();
                            getData();
                          }}
                        >
                          {claimingNft ? "Minting " : "Buy "}{mintAmountBaby} BabyBuds
                        </StyledButton>
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmountBaby();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.Container>
                      </s.Container>
          )
      }}

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24,backgroundColor: roomColor}}
      >
        <s.SpacerLarge />

        <Gallery/>

        <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"} style={{ "max-width":"1100px"}}>


          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={Illus1} id="illustrationRoom"/>
          </s.Container>
          <s.SpacerLarge />

          { babyMode ? <BabyMinter /> : <SeedMinter /> }
            
      </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default Minter;
