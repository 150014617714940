import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;

  
`;

// Used for providing space between components
export const SpacerXLarge = styled.div`
  height: 90px;
  width: 90px;

  
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  
`;

export const TextTitle = styled.p`
  color: #000000;
  font-family: 'Titan One';
  font-style: normal;
  font-size: 50px;
  line-height: 51px;
  font-weight: 600px;
  letter-spacing: 1.4px;
  margin-bottom: 42px;

  @media screen and (max-width: 480px) {
    font-size: 38px;
  }
`;

export const TextSubTitle = styled.p`
  color: #000000;
  line-height: 1.6;
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 500;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const TextPunchline = styled.p`
  color: #000000;
  line-height: 1.6;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const TextCounter = styled.p`
  color: #000000;
  line-height: 1.6;
  font-size: 26px;
  line-height: 1.1;
  font-weight: 600px;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const TextDescription = styled.p`
  color: #000000;
  font-size: 20px;
  line-height: 1.4;

  max-width: 500px;
  margin-bottom: 24px;

  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const TextServicesDesc = styled.p`
  font-size: 0.9rem;
  text-align: center;

  @media screen and (max-width: 500px) {
    display: none;
  }
`

export const TextServiceSubTitle = styled.p`
  color: #000000;
  margin-bottom: 0px;
  font-size: 1.2rem;
  line-height: 1.1;
  font-weight: 550;

`;
