import styled from 'styled-components'

export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  background: #FFFAF2;
  @media screen and (max-width: 1050px) {
    height: 1600px;
  }

  @media screen and (max-width: 480px) {
    height: 2000px;
  }
 `

 export const TextWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 60px;
  padding-right: 60px;
  text-align: right;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row-reverse;
`
export const ServicesWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 750px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      padding: 0 20px;
  }
`
export const ServicesCard = styled.div`
  background: #FFFAF2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 360px;
  width: 210px;
  
  padding:  20px;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 1100px) {
    width: 140px;
}

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
`
export const ServicesIcon = styled.img`
  height: auto;
  width: 80%;
  margin-bottom: 10px;

`
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`


export const Icon = styled.img`

  width: 50px;
  height: 50px;
  padding-right: 0;
  right: -50px;
  top: 35px;
  position: relative;
`