import React, {useState} from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Minter from '../components/Minter';
import "../components/Minter/styles/reset.css";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Sidebar from '../components/SideBar';

const MintingPage = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }
  return (
    <>
    <ScrollToTop />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Navbar toggle={toggle}/>
    <Minter />
    <Footer />
    </>
  )
}

export default MintingPage