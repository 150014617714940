import React from 'react'
import { FooterContainer, FooterWrap,NavSocialMenu, NavSocialItem, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import discordIcon from '../../images/discord-icon.svg'
import twitterIcon from '../../images/twitter-icon.svg'
import openseaIcon from '../../images/opensea-icon.svg'

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              WAGMI Grow House
            </SocialLogo>
            <WebsiteRights>WAGMI Grow House © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <NavSocialMenu style={{"top":"-25%","position":"relative"}}>
            <a href="https://opensea.io/collection/wagmi-seeds"><NavSocialItem src={openseaIcon}/></a>
          <a href="https://discord.gg/PJNu2yuxQA"><NavSocialItem src={discordIcon}/></a>
          <a href="https://www.twitter.com/wagmigrowhouse"><NavSocialItem src={twitterIcon}/></a>         
            </NavSocialMenu>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
