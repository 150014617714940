import styled from 'styled-components';

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  max-width: 1100px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 340px;
  @media screen and (min-width: 900px) {
    width: 850px;
  }
  @media screen and (max-width: 900px) and (min-width: 520px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledImg = styled.img`

  width: 300px;
  @media (min-width: 900px) {
    width: 450px;
  }
  transition: width 0.5s;
`;


export const StyledImg2 = styled.img`

  width: 40%;
  height: auto;
  transition: width 0.5s;
`;

export const BtnLink = styled.button`

  border: 4px solid #000000;
  background: #FFFAF2;
  box-sizing: border-box;
  border-radius: 6px;

  white-space: nowrap;
  padding: 14px 30px;
  font-size: 30px;
  color: #000000;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  
  &:disabled {
    background: #FFBD35  ;
    border: none;
    color: #000000 ;
  }

  &:hover:enabled {
    transition: all 0.2s ease-in-out;
    background: #000000;
    color: #FFFAF2;


  }
`
export const Icon = styled.img`

  width: 40px;
  height: 40px;
  padding-right: 0;
  right: -85px;
  top: 40px;
  position: relative;
`
export const Icon2 = styled.img`

  width: 20px;
  height: 20px;
  padding-right: 0;
  right: 100px;
  top: 75px;
  position: relative;
`
export const CountdownWrap = styled.div`
  display: inline-block;
  list-style-type: none;
  border: 4px solid #000000;
  background: #FFFAF2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px 7px;
  `
  export const DateItemDiv = styled.div`
  display: inline-block;
  list-style-type: none;
  box-sizing: border-box;
  border-right: 1px solid #000000;
  align-items: center;
  text-align: center;
  max-height: 50px;
  min-width: 60px;
  `