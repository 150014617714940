import React from 'react'
import * as s from "../Minter/styles/globalStyles.js";
import IconTitle from '../../images/icon-diams.png'
import Icon1 from '../../images/perso-wi.png'
import Icon2 from '../../images/perso-jo.png'
import Icon3 from '../../images/perso-an.png'
import Icon4 from '../../images/perso-va.png'
import {ServicesContainer, ServicesH1,ServicesPs,Icon, TextWrapper, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './StaffElements'

const Services = () => {
  return (
    <ServicesContainer id='staff'>
            <TextWrapper>
            <Icon src={IconTitle}/>
        <s.TextTitle>The Staff</s.TextTitle>
      </TextWrapper>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon4}/>
          <s.SpacerMedium/>
          <ServicesH2>Busca</ServicesH2>
          <ServicesPs>Marketing</ServicesPs>
          <s.TextServicesDesc>Passioned and well versed in the universe of Crypto Money, NFT and the Blockchain.</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Icon3}/>
        <s.SpacerMedium/>
          <ServicesH2>Vane</ServicesH2>
          <ServicesPs>Artist</ServicesPs>
          <s.TextServicesDesc>Creative and passionate, always ready to add her artistic touch to the creation of new cross breeding.</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2}/>
          <s.SpacerMedium/>
          <ServicesH2>Jo-haze</ServicesH2>
          <ServicesPs>Marketing</ServicesPs>
          <s.TextServicesDesc>Weed epicurean as a vocation, impassioned by the plantation, the evolution and the possible cross breeding between different varieties.</s.TextServicesDesc>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <s.SpacerMedium/>
          <ServicesH2>Growill</ServicesH2>
          <ServicesPs>Engineering</ServicesPs>
          <s.TextServicesDesc>Our logistics engineer who oversees the smooth functioning of each step, from the Seeds to the Big Buds.</s.TextServicesDesc>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
